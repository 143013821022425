"use client";

import Image from "next/image";
import { twMerge } from "tailwind-merge";

interface ButtonProps {
    size?: string;
    hasIcon?: boolean;
    backgroundColor?: string;
    textColor?: string;
    textSize?: string;
    text?: string;
    rounded?: string;
    border?: string;
    iconSrc?: string;
    paddings?: string;
    onClick?: () => void;
    iconPlacement?: "left" | "right";
    iconBackgroundColor?: string;
    buttonType?: "button" | "submit";
    as?: "button" | "a";
    href?: string;
    newTab?: boolean;
    alignment?: string;
}

export default function Button({
    size = "lg:w-fit md:w-fit w-full h-13",
    hasIcon = false,
    backgroundColor = "bg-neutral-800",
    textColor = "text-white",
    textSize = "text-xl font-bold uppercase",
    text = "MEHR DAZU",
    rounded = "rounded-lg",
    border = "",
    iconSrc = "/Icons/arrowRBlack.svg",
    iconPlacement = "right",
    onClick = () => {},
    paddings = "px-6 py-3",
    iconBackgroundColor = "bg-zinc-300",
    buttonType = "submit",
    as = "button",
    href = undefined,
    newTab = false,
    alignment = "justify-center",
}: ButtonProps) {
    const Component = as;
    return (
        <Component
            className={twMerge(
                "  items-start gap-2 inline-flex",
                size,
                backgroundColor,
                border,
                rounded,
                paddings,
                alignment,
            )}
            type={buttonType}
            onClick={onClick}
            href={href}
            target={newTab ? "_blank" : undefined}
            rel={newTab ? "noopener noreferrer" : undefined}
        >
            {hasIcon && iconPlacement === "left" && (
                <div className="w-6 h-6 relative">
                    <Image
                        alt=""
                        width={20}
                        height={20}
                        className={"w-6 h-6 left-0 top-1 absolute rounded-full"}
                        src={iconSrc}
                    />
                </div>
            )}
            <div className={twMerge("text-center eading-7", textColor, textSize)}>
                {text}
            </div>
            {hasIcon && iconPlacement === "right" && (
                <div>
                    <Image
                        alt=""
                        width={20}
                        height={20}
                        className="w-6 h-6 relative"
                        src={iconSrc}
                    />
                </div>
            )}
        </Component>
    );
}
